import React from "react"
import { Button, Icon, Menu } from "antd"
import { Link, StaticQuery, graphql } from "gatsby"
import { Location } from "@reach/router"
import style from "./NavMenu.module.scss"

class NavMenu extends React.Component {
  state = {
    currentSubMenu: "",
  }

  openSubMenu = label => {
    if (this.state.currentSubMenu !== label) {
      this.setState({
        currentSubMenu: label,
      })
    }
  }

  closeSubMenu = label => {
    if (this.state.currentSubMenu === label) {
      this.setState({
        currentSubMenu: "",
      })
    }
  }

  render() {
    const { logo, name, nodes, navStyle, navTheme, bookingEngine } = this.props

    return (
      <Location>
        {({ location }) => (
          <Menu
            className={style[navTheme || "default"]}
            selectedKeys={[location.pathname]}
            openKeys={[this.state.currentSubMenu]}
            mode="horizontal"
            theme="light"
            style={
              navStyle || {
                fontWeight: 700,
                textAlign: `center`,
              }
            }
          >
            <Menu.Item key="logo" className={style.logo}>
              <Link to="/">
                <img
                  className={style.navLogo}
                  src={logo}
                  alt={`${name}'s logo`}
                />
              </Link>
            </Menu.Item>
            {nodes.map(node => {
              // see if this item should be excluded from the menu on this page
              if (node.hideOn.includes(location.pathname)) {
                return false
              }
              // if there are nodes that list this one as a parent,
              // create a submenu for those nodes.
              if (
                nodes.find(
                  n => n.label !== node.label && n.childOf === node.label
                )
              ) {
                return (
                  <Menu.SubMenu
                    key={node.label}
                    title={
                      <span>
                        {node.icon ? (
                          <>
                            <Icon type={node.icon} /> {node.label}
                          </>
                        ) : (
                          <>
                            {node.label} <Icon type="caret-down" />
                          </>
                        )}
                      </span>
                    }
                    onTitleClick={() => this.openSubMenu(node.label)}
                    onMouseEnter={() => this.openSubMenu(node.label)}
                    onMouseLeave={() => this.closeSubMenu(node.label)}
                    // populate this submenu with all nodes that list the current
                    // node as a parent — except for the current node.
                    children={nodes
                      .filter(
                        item =>
                          item.label !== node.label &&
                          item.childOf === node.label
                      )
                      .map(child => (
                        <Menu.Item key={child.href}>
                          <Link to={child.href}>
                            {child.icon ? <Icon type={child.icon} /> : ""}
                            {child.label}
                          </Link>
                        </Menu.Item>
                      ))}
                  />
                )

                // if this node had any children, it wouldn't have made it to this point.
                // however, it may still be a node that belongs in a submenu, so make sure
                // that it lists itself as its own parent before adding it the main menu.
              } else if (node.label === node.childOf) {
                return (
                  <Menu.Item
                    key={node.href}
                    className={node.label === "Book Now" ? style.ctaItem : ""}
                  >
                    {node.label === "Book Now" ? (
                      <a href={bookingEngine}>
                        <Button className={style.cta} size="large" type="ghost">
                          {node.label}
                        </Button>
                      </a>
                    ) : node.href.startsWith(`http`) ? (
                      <a href={node.href}>
                        {node.icon ? <Icon type={node.icon} /> : ""}
                        {node.label}
                      </a>
                    ) : (
                      <Link to={node.href}>
                        {node.icon ? <Icon type={node.icon} /> : ""}
                        {node.label}
                      </Link>
                    )}
                  </Menu.Item>
                )
              }

              // return something here to appease eslint's arrow-function-
              // must-return-something rule
              return true
            })}
          </Menu>
        )}
      </Location>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        navMenu: file(name: { eq: "navMenu" }) {
          childrenNavMenuJson {
            href
            icon
            label
            childOf
            hideOn
          }
        }
        ctf: contentfulHotel(name: { eq: "Lakeside Lodge Clemson" }) {
          name
          logo {
            file {
              url
            }
          }
        }
      }
    `}
    render={data => (
      // import TopNav, MobileNav, SideNav, etc, and then render
      // whichever one is appropriate for the media here:
      <div id="reg-header">
        <NavMenu
          bookingEngine={process.env.BOOKING_ENGINE}
          logo={data.ctf.logo.file.url}
          name={data.ctf.name}
          navStyle={props.navStyle}
          navTheme={props.navTheme}
          nodes={data.navMenu.childrenNavMenuJson}
        />
      </div>
    )}
  />
)
