import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"

class Favicon extends React.Component {
  static propTypes = {
    format: PropTypes.string,
    name: PropTypes.string,
    path: PropTypes.string,
    svg: PropTypes.bool,
    widths: PropTypes.arrayOf(PropTypes.number),
  }

  static defaultProps = {
    format: ".png",
    name: "favicon",
    path: "/",
    svg: true,
    widths: [16, 32, 128, 144, 152, 167, 180, 192, 196],
  }

  render() {
    const { format, name, path, svg, widths } = this.props

    const fixedPath = path.endsWith("/") ? path : `${path}/`
    const fixedFormat = format.startsWith(".") ? format : `.${format}`

    return (
      <Helmet>
        {widths.map((width, index) => (
          <link
            key={`${width}-${index}`}
            rel="icon"
            href={`${fixedPath}${name}-${width}x${width}${fixedFormat}`}
          />
        ))}
        {svg ? <link rel="icon" href={`${fixedPath}${name}.svg`} /> : <></>}
      </Helmet>
    )
  }
}

export default Favicon
