import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Col, Typography } from "antd"
import style from "./Footer.module.scss"

const { Text } = Typography

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "business" }) {
        childDataJson {
          name
        }
      }
    }
  `)

  const year = () => new Date().getFullYear()
  const name = data.file.childDataJson.name

  return (
    <Col className={style.copyrightColumn} span={24}>
      <Text className={style.copyrightText}>
        &copy; {year()} {name}. All Rights Reserved.
      </Text>
    </Col>
  )
}
