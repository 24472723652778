import React, { useEffect } from "react"
import { BackTop, Icon, Layout } from "antd"
import { useStaticQuery, graphql } from "gatsby"
import style from "./Shell.module.scss"
import "../../styles/globals.scss"

import Favicon from "../Favicon/Favicon"
import PromotionBar from "../PromotionBar/PromotionBar"
import TopNav from "../NavMenu/TopNav"
import NavMenuMobile from "../NavMenu/MobileNav"
import Footer from "../Footer/Footer"

const domRendered =
  typeof CustomEvent !== "undefined" ? new CustomEvent("domrendered") : false

export default props => {
  // const Media = useContext(MediaContext)

  useEffect(() => {
    // The CallRail tag in GTM listens for this event to fire,
    // and when it does, calls CallRail's swap() method.
    if (domRendered) {
      window.dispatchEvent(domRendered)
    }
  })

  // query global content for Shell components from
  // data/globalShellContent.json
  const data = useStaticQuery(graphql`
    query {
      contentfulHotel(name: { eq: "Lakeside Lodge Clemson" }) {
        name
        phone
        email
        streetAddress
        cityStateZip
        facebookHandle
        instagramHandle
        twitterHandle
      }
    }
  `)

  const hotel = data.contentfulHotel

  // refer to props for shell component content first, but
  // fall back to the contents of globalShellContent.json
  // if content isn't provided. this allows shell component
  // content to be specified at the page level.
  const page = {
    footer: props.footerContent || {
      contactInfo: {
        phone: hotel.phone,
        email: hotel.email,
        streetAddress: hotel.streetAddress,
        cityStateZip: hotel.cityStateZip,
      },
      socialPlatforms: {
        facebook: hotel.facebookHandle,
        instagram: hotel.instagramHandle,
        twitter: hotel.twitterHandle,
      },
      cta: {
        link: process.env.BOOKING_ENGINE,
        buttonIcon: `calendar`,
        buttonText: `Book Now!`,
      },
    },
  }

  const navProps = {
    navStyle: props.navStyle,
    navTheme: props.navTheme,
  }

  const backToTop = props.backToTop || 1500

  const backTopProps = {
    className: style.backTopWrapper,
    visibilityHeight: backToTop,
  }

  return (
    <>
      <Favicon path="/favicons/" />
      <Layout className={style.layout}>
        <PromotionBar />
        <Layout.Header className={style.header} style={props.headerStyle}>
          <TopNav {...navProps} />
          <NavMenuMobile {...navProps} />
        </Layout.Header>
        <Layout.Content className={style.content}>
          {backToTop ? (
            <BackTop {...backTopProps}>
              <div className={style.backTop}>
                <Icon type="arrow-up" />
              </div>
            </BackTop>
          ) : (
            <></>
          )}
          {props.children}
        </Layout.Content>
        <Layout.Footer className={style.footer}>
          <Footer {...page.footer} />
        </Layout.Footer>
      </Layout>
    </>
  )
}
