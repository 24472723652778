import React from "react"
import { Col, Typography, Icon } from "antd"
import style from "./Footer.module.scss"

const { Title } = Typography

export default ({ facebook, instagram, twitter }) => {
  return (
    <Col
      className={style.footerColumn}
      xs={{ span: 24, order: 2 }}
      sm={6}
      md={8}
      lg={8}
    >
      <Title level={4}>Follow Us</Title>
      {facebook !== null ? (
        <a key="fb" href={`https://www.facebook.com/${facebook}`}>
          <Icon type="facebook" />
        </a>
      ) : (
        <></>
      )}
      {twitter !== null ? (
        <a key="tw" href={`https://www.twitter.com/${twitter}`}>
          <Icon type="twitter" />
        </a>
      ) : (
        <></>
      )}
      {instagram !== null ? (
        <a key="ig" href={`https://www.instagram.com/${instagram}`}>
          <Icon type="instagram" />
        </a>
      ) : (
        <></>
      )}
    </Col>
  )
}
