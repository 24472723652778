import React from "react"
import { Col } from "antd"
import { useStaticQuery, graphql } from "gatsby"
import style from "./Footer.module.scss"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "globalShellContent" }) {
        childDataJson {
          Footer {
            iconColumn
          }
        }
      }
      ctf: contentfulHotel(name: { eq: "Lakeside Lodge Clemson" }) {
        icon {
          file {
            url
          }
        }
      }
    }
  `)

  const show = data.file.childDataJson.Footer.iconColumn

  const colProps = {
    className: style.topColumn,
    span: 24,
  }

  const imgProps = {
    alt: `Lakeside Lodge Mountains & Lake Icon`,
    src: data.ctf.icon.file.url,
  }

  const col = (
    <Col {...colProps}>
      <img {...imgProps} alt={imgProps.alt} />
    </Col>
  )

  if (show) {
    return col
  }
}
