import React from "react"
import { Button, Col, Drawer, Icon, Menu, Row } from "antd"
import { Link, StaticQuery, graphql } from "gatsby"
import { Location } from "@reach/router"
import style from "./MobileNav.module.scss"

class MobileNavMenu extends React.Component {
  state = {
    currentSubMenu: "",
    visible: false,
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  closeDrawer = () => {
    this.setState({
      visible: false,
    })
  }

  openSubMenu = label => {
    if (this.state.currentSubMenu !== label) {
      this.setState({
        currentSubMenu: label,
      })
    }
  }

  closeSubMenu = label => {
    if (this.state.currentSubMenu === label) {
      this.setState({
        currentSubMenu: "",
      })
    }
  }

  render() {
    const { logo, name, nodes, bookingEngine } = this.props

    const logoProps = {
      alt: `${name}'s logo`,
      className: style.navLogo,
      src: logo,
    }

    const buttonProps = {
      icon: "menu",
      type: "link",
      onClick: this.showDrawer,
      style: {
        fontSize: 22,
        marginLeft: 15,
      },
    }

    return (
      <Location>
        {({ location }) => (
          <>
            <Row
              id="nav-menu-mobile"
              align="middle"
              justify="space-between"
              type="flex"
              style={{ padding: `0 50px` }}
            >
              <Col span={12}>
                <Link to="/">
                  <img {...logoProps} alt={logoProps.alt} />
                </Link>
              </Col>
              <Col span={1}>
                <Button {...buttonProps} />
              </Col>
            </Row>
            <Drawer
              // title={this.props.name}
              placement="right"
              closable={true}
              onClose={this.closeDrawer}
              visible={this.state.visible}
              width={225}
            >
              <Menu
                className={style.mobileNav}
                selectedKeys={[location.pathname]}
                mode="inline"
                style={{
                  borderRight: 0,
                  fontWeight: 700,
                  marginTop: 36,
                  textTransform: `uppercase`,
                  width: `100%`,
                }}
              >
                {nodes.map(node => {
                  if (node.hideOn.includes(location.pathname)) {
                    return false
                  }
                  if (
                    nodes.find(
                      n => n.label !== node.label && n.childOf === node.label
                    )
                  ) {
                    return (
                      <Menu.SubMenu
                        key={node.label}
                        title={
                          <span>
                            {node.icon ? (
                              <>
                                <Icon type={node.icon} /> {node.label}
                              </>
                            ) : (
                              <>{node.label}</>
                            )}
                          </span>
                        }
                        onTitleClick={() => this.openSubMenu(node.label)}
                        onMouseEnter={() => this.openSubMenu(node.label)}
                        onMouseLeave={() => this.closeSubMenu(node.label)}
                        children={nodes
                          .filter(
                            item =>
                              item.label !== node.label &&
                              item.childOf === node.label
                          )
                          .map(child => (
                            <Menu.Item key={child.href}>
                              <Link to={child.href}>
                                {child.icon ? <Icon type={child.icon} /> : ""}
                                {child.label}
                              </Link>
                            </Menu.Item>
                          ))}
                      />
                    )
                  } else if (node.label === node.childOf) {
                    return (
                      <Menu.Item key={node.href}>
                        {node.label === "Book Now" ? (
                          <a href={bookingEngine}>
                            <Button className={style.cta} block type="ghost">
                              {node.label}
                            </Button>
                          </a>
                        ) : node.href.startsWith(`http`) ? (
                          <a href={node.href}>
                            {node.icon ? <Icon type={node.icon} /> : ""}
                            {node.label}
                          </a>
                        ) : (
                          <Link to={node.href}>
                            {node.icon ? <Icon type={node.icon} /> : ""}
                            {node.label}
                          </Link>
                        )}
                      </Menu.Item>
                    )
                  }
                  return true
                })}
              </Menu>
            </Drawer>
          </>
        )}
      </Location>
    )
  }
}

// export default MobileNavMenu

export default props => (
  <StaticQuery
    query={graphql`
      {
        navMenu: file(name: { eq: "navMenu" }) {
          childrenNavMenuJson {
            href
            icon
            label
            childOf
            hideOn
          }
        }
        ctf: contentfulHotel(name: { eq: "Lakeside Lodge Clemson" }) {
          name
          logo {
            file {
              url
            }
          }
        }
      }
    `}
    render={data => (
      // import TopNav, MobileNav, SideNav, etc, and then render
      // whichever one is appropriate for the media here:
      <div id="mobile-header">
        <MobileNavMenu
          bookingEngine={process.env.BOOKING_ENGINE}
          logo={data.ctf.logo.file.url}
          name={data.ctf.name}
          navStyle={props.navStyle}
          navTheme={props.navTheme}
          nodes={data.navMenu.childrenNavMenuJson}
        />
      </div>
    )}
  />
)
